.walletLinksDiv {
    position:relative !important;
    overflow: hidden !important; 
    transition: 300ms ease-in !important;
    padding-left: 1rem !important;

}
.walletLinks{
    position: relative !important;
    left: 0rem !important;
    transition: 300ms ease-in !important;
}

.noWalletLinks {
    position: absolute !important;
    left: -5rem !important;
    transition: 500ms ease-in !important;  
}

.dataTransLinks {
    position:relative !important;
    overflow: hidden !important; 
    transition: 300ms ease-in !important;
    padding-left: 3rem !important; 
}