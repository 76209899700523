
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600&family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  border: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
a{
  text-decoration: none;
}

:root {
  --animate-duration: 3s;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
  
}
#nav-check:checked ~ .xure-nav{
  top: 72px;
  transition: 0.5s;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.servicediv::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.servicediv {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.servicediv {
  margin-top: 2rem;
  white-space: nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.mdchevronright, .mdchevronleft {
  opacity: 0.5;
  cursor: pointer;
  border-radius: 50%;
}

.services:hover .mdchevronright, .services:hover .mdchevronleft{
  background-color: rgb(156 163 175);;
  opacity: 1;
}
.eachservice > h4{
  background-color: rgb(156 163 175);
  border-radius: 2rem;
}
.eachservice{
  box-shadow: 0 10px 15px -3px black, 0 4px 6px -4px black;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 200ms;
}
.eachservice:hover{
  background-color: black;
  color: white; 
  transform: scaleX(1.05);
}
.eachservice:hover >h3{
  color: white;
}

.eachservice:hover >h4 {
  background: white;
}
.dataplandiv >div:hover {
  box-shadow: 0 10px 15px -3px black, 0 4px 6px -4px black;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 200ms;
}

@media screen and (max-width: 991.98px){
  .whyusimg {
    display: none !important;
  }
  .whyusheader {
    font-size: 40px !important;
  }
  .whyusmsg {
    width: 90% !important;
    font-size: 18px !important;
  }
  .awesomeservimg {
    display: none !important;
  }
  .awesomeservmsg {
    font-size: 18px !important;
  }
  .agent > img{
    display: none !important;
  }
}
